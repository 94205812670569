<template>
  <header>
    <NuxtLink
      v-if="shopBaseUrl"
      class="logo"
      :to="shopBaseUrl"
    >
      <img
        alt=""
        src=""
      >
    </NuxtLink>
    <ul>
      <li
        v-for="(usp, index) in usps"
        :key="index"
        :class="{ 'visible': index === currentIndex }"
      >
        <Icon name="material-symbols:check" />
        {{ usp }}
      </li>
    </ul>
  </header>
</template>

<script setup>
  import getUsps from "../../apollo/queries/getUsps.gql";
  import { onMounted } from "vue";

  const storeCode = useStoreCode();
  const usps = useState('usps', () => []);
  const currentIndex = ref(0);
  const runtimeConfig = useRuntimeConfig();
  const shopBaseUrl = ref(false)

  onMounted(async () => {
    shopBaseUrl.value = runtimeConfig.public.SHOP.BASE_URL ? runtimeConfig.public.SHOP.BASE_URL.replace('store_code', storeCode.value) : false;

    const { onResult } = await useQuery(getUsps, { store_code: storeCode.value });
    onResult((result) => {
      usps.value = result?.data?.usps || []
      startFading()
    });
  });

  const startFading = () => setInterval(() => currentIndex.value = (currentIndex.value + 1) % usps.value.length, 3000);
</script>

<style lang="scss" scoped>
  header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    position: fixed;
    line-height: 1;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 15px;
    color: var(--topbar-color, $white);
    background: var(--topbar-bg, $primary);
    padding: var(--topbar-padding-vertical, 10px) 10px var(--topbar-padding-vertical, 10px) var(--topbar-padding-left-mobile, 10px);

    @include min-screen('medium') {
      padding: var(--topbar-padding-vertical, 10px) 10px var(--topbar-padding-vertical, 10px) var(--topbar-padding-left, 10px);
      grid-template-columns: minmax(100px, min-content) 1fr minmax(100px, min-content);
    }

    .logo {
      width: var(--logo-width);
      height: var(--logo-height);

      img {
        object-fit: contain;
        content: var(--logo-url);
      }
    }

    ul {
      gap: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: var(--topbar-usps-align, center);

      @include min-screen('medium') {
        gap: 30px;
      }

      li {
        align-items: center;
        padding: 0 5px;
        font-size: 14px;
        font-weight: var(--topbar-usps-font-weight, 400);
        display: none;

        @include min-screen('medium') {
          display: flex;
        }

        &.visible {
          display: flex;
        }

        svg {
          font-size: 1.25em;
          margin-right: 10px;
        }
      }
    }
  }
</style>
